import React from "react";
import QrCodeGenerator from "./QrCodeGenerator";

const UrlRow = ({ link, editableLink, onSave, onDelete, onStatistics, onEditChange }) => {
  return (
    <tr className="dark:bg-nord-polarNight2 bg-nord-snowStorm2 
                 hover:dark:bg-nord-polarNight3 hover:bg-nord-snowStorm1
                 transition-colors duration-200">
      <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3">
        <a
          href={link.short_url}
          target="_blank"
          rel="noopener noreferrer"
          className="dark:text-nord-frost2 text-nord-frost4 underline"
        >
          {link.short_url}
        </a>
      </td>
      <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3">
        <input
          type="text"
          value={editableLink.original_url || ""}
          onChange={(e) => onEditChange(link.id, "original_url", e.target.value)}
          className="nord-input w-full"
        />
      </td>
      <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3">
        <input
          type="text"
          value={editableLink.utm_parameters || ""}
          onChange={(e) => onEditChange(link.id, "utm_parameters", e.target.value)}
          className="nord-input w-full"
        />
      </td>
      <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 space-x-2">
        <button
          onClick={() => onSave(link.id)}
          className="bg-nord-aurora4 hover:bg-opacity-80 text-white px-3 py-1 rounded transition-colors"
        >
          Save
        </button>
        <button
          onClick={() => onDelete(link.id)}
          className="bg-nord-aurora1 hover:bg-opacity-80 text-white px-3 py-1 rounded transition-colors"
        >
          Delete
        </button>
        <button
          onClick={() => onStatistics(link.id)}
          className="bg-nord-frost4 hover:bg-nord-frost3 text-white px-3 py-1 rounded transition-colors"
        >
          Statistics
        </button>
        <QrCodeGenerator 
          linkId={link.id} 
          shortUrl={link.short_url}
        />
      </td>
    </tr>
  );
};

export default UrlRow;
