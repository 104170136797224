import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ShortenPage = () => {
  const [url, setUrl] = useState("");
  const [utmString, setUtmString] = useState("");
  const [customEnding, setCustomEnding] = useState("");
  const [selectedDomain, setSelectedDomain] = useState(""); // This will store domain ID
  const [shortenedUrl, setShortenedUrl] = useState("");
  const [domains, setDomains] = useState([]);
  const navigate = useNavigate();

  // Fetch available domains from the backend
  useEffect(() => {
    axios
      .get("/api/user/domains", { withCredentials: true })
      .then((response) => setDomains(response.data))
      .catch((error) => {
        console.error("Error fetching domains:", error);
        // Redirect to login page if unauthorized (401)
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate]);

  const parseUtmString = (utmStr) => {
    if (!utmStr) return {};
    return utmStr.split("&").reduce((acc, param) => {
      const [key, value] = param.split("=");
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const handleShorten = async () => {
    if (!url) {
      alert("Please enter a valid URL.");
      return;
    }
    const utmParameters = parseUtmString(utmString);
    const payload = {
      url,
      utm_parameters: utmParameters,
      domain_id: selectedDomain, // Optional custom domain
      custom_ending: customEnding, // Optional custom ending
    };

    try {
      const response = await axios.post("/api/shorten", payload, { withCredentials: true });
      if (response.data.short_url) {
        setShortenedUrl(response.data.short_url);
      } else {
        alert("Failed to shorten URL. Please check your input and try again.");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred. Please try again.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6 dark:text-nord-snowStorm1 text-nord-polarNight1">
        Shorten URL
      </h1>
      <div className="nord-card p-6 max-w-2xl mx-auto">
        <div className="mb-4">
          <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
            Enter URL:
          </label>
          <input
            type="text"
            placeholder="https://example.com/your-long-url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="nord-input w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
            UTM Query String (Optional):
          </label>
          <textarea
            placeholder="utm_source=newsletter&utm_medium=email&utm_campaign=summer2023"
            value={utmString}
            onChange={(e) => setUtmString(e.target.value)}
            className="nord-input w-full h-24"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
            Select Domain (Optional):
          </label>
          <select
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            className="nord-input w-full"
          >
            <option value="">Default Domain</option>
            {domains.map((domain) => (
              <option key={domain.id} value={domain.id}>
                {domain.domain}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-6">
          <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
            Custom Ending (Optional):
          </label>
          <input
            type="text"
            placeholder="my-custom-url"
            value={customEnding}
            onChange={(e) => setCustomEnding(e.target.value)}
            className="nord-input w-full"
          />
        </div>
        <button
          onClick={handleShorten}
          className="btn-primary"
        >
          Shorten
        </button>
        {shortenedUrl && (
          <div className="mt-6 p-4 dark:bg-nord-polarNight3 bg-nord-snowStorm1 rounded-md">
            <p className="font-medium mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
              Shortened URL:
            </p>
            <a
              href={shortenedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="dark:text-nord-frost2 text-nord-frost4 underline"
            >
              {shortenedUrl}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShortenPage;
