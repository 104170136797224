import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // Store the whole user object, not just logged-in status
  const [user, setUser] = useState(null); 
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    // Check authentication status when the app loads
    const checkAuthStatus = async () => {
      setIsLoading(true);
      try {
        // Use the updated /api/auth/status endpoint
        const response = await fetch('/api/auth/status', { credentials: 'include' });
        if (response.ok) {
          const data = await response.json();
          if (data.isLoggedIn && data.user) {
            setUser(data.user); // Store the user object
            console.log("AuthContext: User logged in", data.user);
          } else {
            setUser(null);
          }
        } else {
          // Assume logged out if status check fails (e.g., 401)
          setUser(null);
        }
      } catch (error) {
        console.error("Error checking auth status:", error);
        setUser(null); // Assume logged out on network error
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthStatus();
    
    // Remove simulation - rely on backend status check
    /*
    const simulateAuthCheck = () => {
      // Check if we have a token in localStorage or sessionStorage
      const hasToken = !!localStorage.getItem('authToken') || !!sessionStorage.getItem('authToken');
      // This simulation is insufficient now, we need user details
      // setIsLoggedIn(hasToken);
      setIsLoading(false);
    };
    simulateAuthCheck();
    */
  }, []);

  // Login function: Now expects the user object after successful API login
  const login = (userData) => {
    setUser(userData);
    // Maybe store a token or indicator if needed, but session cookie is primary
    // localStorage.setItem('authToken', 'dummy-token'); 
    console.log("AuthContext: login function called", userData);
  };

  // Logout function: Clears user state and calls backend logout
  const logout = async () => {
    try {
        // Call backend logout API first
        await fetch('/api/auth/logout', {
            method: 'POST',
            credentials: 'include'
        });
    } catch (error) {
        console.error("Error calling backend logout:", error);
        // Proceed with frontend logout even if backend call fails
    } finally {
        setUser(null);
        // localStorage.removeItem('authToken'); 
        console.log("AuthContext: logout function called");
    }
  };

  return (
    // Provide user object, loading state, login/logout functions
    <AuthContext.Provider value={{ user, isLoggedIn: !!user, isLoading, login, logout }}>
      {/* Render children only after checking status */}
      {!isLoading ? children : <div className="flex justify-center items-center h-screen"><div>Loading authentication...</div></div>} 
    </AuthContext.Provider>
  );
};

// Custom hook remains the same
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 