import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function ManageSubusersPage() {
    const [subusers, setSubusers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isAdding, setIsAdding] = useState(false);

    const { user, isLoading: isAuthLoading } = useAuth(); // Destructure isLoading as isAuthLoading
    const navigate = useNavigate();

    // Redirect if user is not a company admin (wait for auth loading)
    useEffect(() => {
        if (!isAuthLoading && user && user.account_type !== 'company_admin') {
            console.warn("Redirecting: Only company admins can access this page.");
            navigate('/manage'); // Redirect to a default page
        }
    }, [user, isAuthLoading, navigate]);

    const fetchSubusers = useCallback(async () => {
        // Ensure user is loaded and is an admin before fetching
        if (isAuthLoading || !user || user.account_type !== 'company_admin') {
            return; 
        }
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.get('/api/company/subusers', { withCredentials: true });
            setSubusers(response.data);
        } catch (err) {
            console.error("Error fetching subusers:", err);
            setError(err.response?.data?.message || 'Failed to fetch subusers.');
             if (err.response?.status === 401 || err.response?.status === 403) {
                 navigate('/'); // Redirect to login if unauthorized/forbidden
             }
        } finally {
            setIsLoading(false);
        }
    }, [navigate, user, isAuthLoading]); // Add user and isAuthLoading

    useEffect(() => {
        // Fetch subusers only if the user is confirmed to be a company admin and auth isn't loading
         if (!isAuthLoading && user && user.account_type === 'company_admin') {
            fetchSubusers();
        }
    }, [user, isAuthLoading, fetchSubusers]); // Add isAuthLoading

    const handleAddSubuser = async (e) => {
        e.preventDefault();
        if (!newUsername || !newPassword) {
            setError("Username and password cannot be empty.");
            return;
        }
        setIsAdding(true);
        setError('');
        setSuccess('');
        try {
            await axios.post('/api/company/subusers', { username: newUsername, password: newPassword }, { withCredentials: true });
            setSuccess(`Subuser '${newUsername}' created successfully!`);
            setNewUsername('');
            setNewPassword('');
            // Short delay before refetching to allow backend potentially
            setTimeout(fetchSubusers, 100); 
        } catch (err) {
            console.error("Error adding subuser:", err);
            setError(err.response?.data?.message || 'Failed to add subuser.');
        } finally {
            setIsAdding(false);
        }
    };

    const handleDeleteSubuser = async (subuserId, subuserName) => {
        if (window.confirm(`Are you sure you want to delete subuser '${subuserName}'?`)) {
            setIsLoading(true); // Use main loading indicator for delete as well
            setError('');
            setSuccess('');
            try {
                await axios.delete(`/api/company/subusers/${subuserId}`, { withCredentials: true });
                setSuccess(`Subuser '${subuserName}' deleted successfully!`);
                // Short delay before refetching
                setTimeout(fetchSubusers, 100); 
            } catch (err) {
                console.error("Error deleting subuser:", err);
                setError(err.response?.data?.message || 'Failed to delete subuser.');
                setIsLoading(false); // Stop loading on error
            } 
            // Don't set isLoading false here if fetchSubusers will run
        }
    };

    // Show loading if Auth state is loading
    if (isAuthLoading) {
        return <div className="text-center p-4">Loading authentication...</div>;
    }
    
    // This state should be brief due to the redirect effect
    if (!user || user.account_type !== 'company_admin') {
         return <div className="text-center p-4">Verifying access...</div>; 
    }


    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6 dark:text-nord-snowStorm1 text-nord-polarNight1">
                Manage Subusers
            </h1>

            {/* Add Subuser Form */}
            <div className="nord-card p-6 mb-8 max-w-lg mx-auto">
                <h2 className="text-xl font-semibold mb-4">Add New Subuser</h2>
                <form onSubmit={handleAddSubuser}>
                    <div className="mb-4">
                        <label className="block mb-1 dark:text-nord-snowStorm1 text-nord-polarNight1" htmlFor="newUsername">
                            Username:
                        </label>
                        <input
                            id="newUsername"
                            type="text"
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                            className="nord-input w-full"
                            required
                            minLength="3"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 dark:text-nord-snowStorm1 text-nord-polarNight1" htmlFor="newPassword">
                            Password:
                        </label>
                        <input
                            id="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="nord-input w-full"
                            required
                            minLength="6"
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn-primary w-full"
                        disabled={isAdding}
                    >
                        {isAdding ? 'Adding...' : 'Add Subuser'}
                    </button>
                </form>
            </div>

            {/* Status Messages */}
            {error && (
                <div className="bg-nord-aurora1 bg-opacity-20 text-nord-aurora1 p-3 rounded-md mb-4 max-w-lg mx-auto">
                    Error: {error}
                </div>
            )}
            {success && (
                 <div className="bg-nord-aurora4 bg-opacity-20 text-nord-aurora4 p-3 rounded-md mb-4 max-w-lg mx-auto">
                    {success}
                </div>
            )}


            {/* Subusers List */}
            <div className="nord-card p-6">
                <h2 className="text-xl font-semibold mb-4">Existing Subusers</h2>
                {isLoading ? (
                    <p className="text-center p-4">Loading subusers...</p>
                ) : subusers.length === 0 ? (
                    <p className="text-center p-4">No subusers found.</p>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full border-collapse dark:border-nord-polarNight4 border-nord-frost1">
                             <thead>
                                <tr className="dark:bg-nord-polarNight3 bg-nord-snowStorm1">
                                    <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-left">Username</th>
                                    {/* <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-left">Created At</th> */}
                                    <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subusers.map((subuser) => (
                                    <tr key={subuser.id} className="dark:bg-nord-polarNight2 bg-nord-snowStorm2 hover:dark:bg-nord-polarNight3 hover:bg-nord-snowStorm1 transition-colors duration-200">
                                        <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3">{subuser.username}</td>
                                        {/* <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3">{new Date(subuser.created_at).toLocaleString()}</td> */}
                                        <td className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-right">
                                            <button
                                                onClick={() => handleDeleteSubuser(subuser.id, subuser.username)}
                                                className="bg-nord-aurora1 hover:bg-opacity-80 text-white px-3 py-1 rounded transition-colors text-sm"
                                                disabled={isLoading} // Disable delete while list is loading
                                            >
                                                Delete
                                            </button>
                                             {/* Add Edit/Reset Password button here if needed */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ManageSubusersPage; 