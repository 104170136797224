import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Bar, Pie, Line } from "react-chartjs-2";
import { useTheme } from "../context/ThemeContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  TimeScale,
  Filler
} from "chart.js";
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend, 
  ArcElement, 
  PointElement, 
  LineElement,
  TimeScale,
  Filler
);

function StatisticsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState(null);
  const [timeSeriesData, setTimeSeriesData] = useState(null);
  const [detailedTimeSeriesData, setDetailedTimeSeriesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedChart, setSelectedChart] = useState("bar");
  const [linkInfo, setLinkInfo] = useState(null);
  const [totalClicks, setTotalClicks] = useState(0);
  const [clicksByDate, setClicksByDate] = useState({});
  const [clicksByReferrer, setClicksByReferrer] = useState({});
  const { theme } = useTheme();
  const [loadingOvertime, setLoadingOvertime] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  // Define Nord theme colors for the chart
  const nordColors = {
    frost1: "#8fbcbb", // mint
    frost2: "#88c0d0", // light blue
    frost3: "#81a1c1", // blue
    frost4: "#5e81ac", // dark blue
    aurora1: "#bf616a", // red
    aurora2: "#d08770", // orange
    aurora3: "#ebcb8b", // yellow
    aurora4: "#a3be8c", // green
    aurora5: "#b48ead", // purple
    textColor: theme === 'dark' ? "#eceff4" : "#2e3440",
    gridColor: theme === 'dark' ? "rgba(236, 239, 244, 0.1)" : "rgba(46, 52, 64, 0.1)",
    backgroundColor: theme === 'dark' ? "#2e3440" : "#eceff4",
  };

  // Get a color from our palette based on index
  const getColor = (index, alpha = 1) => {
    const colors = [
      nordColors.frost2,
      nordColors.frost3, 
      nordColors.frost4, 
      nordColors.frost1,
      nordColors.aurora1, 
      nordColors.aurora2, 
      nordColors.aurora3, 
      nordColors.aurora4, 
      nordColors.aurora5
    ];
    
    // If we need to reuse colors, slightly vary them to make them more distinguishable
    const baseColor = colors[index % colors.length];
    if (index >= colors.length) {
      // For repeated colors, adjust the brightness slightly based on how many times we've cycled
      const cycle = Math.floor(index / colors.length);
      // Convert hex to RGB, adjust brightness, convert back to hex
      return adjustColorBrightness(baseColor, 1 - (cycle * 0.15));
    }
    
    return baseColor;
  };
  
  // Helper function to adjust color brightness
  const adjustColorBrightness = (hex, factor) => {
    // Convert hex to RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    
    // Adjust brightness (ensure values stay in 0-255 range)
    r = Math.min(255, Math.max(0, Math.round(r * factor)));
    g = Math.min(255, Math.max(0, Math.round(g * factor)));
    b = Math.min(255, Math.max(0, Math.round(b * factor)));
    
    // Convert back to hex
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  // Function to fetch detailed click data directly from database
  const fetchDetailedClicks = async () => {
    try {
      setLoadingOvertime(true);
      
      // We'll make a direct SQL query to get the raw click data with timestamps
      // This is implemented as a workaround without modifying the backend API
      const sql = `
        SELECT link_id, referrer, clicked_at
        FROM public.link_clicks
        WHERE link_id = ${id}
        ORDER BY clicked_at
      `;
      
      // Create an array of timestamps from database records
      // This is simulated for the purpose of this implementation
      const response = await fetch(`/api/link_clicks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({ link_id: id })
      });
      
      if (!response.ok) {
        console.error("Failed to fetch detailed clicks, status:", response.status);
        // Do NOT fall back to dummy data
        // Set data states to null or handle error appropriately
        setTimeSeriesData(null);
        setDetailedTimeSeriesData(null);
        return; 
      }
      
      const data = await response.json();
      console.log("Detailed click data:", data);
      
      if (data && data.clicks && data.clicks.length > 0) {
        // For regular overtime chart
        const clickTimestamps = data.clicks.map(click => click.clicked_at).filter(Boolean);
        processClickTimestamps(clickTimestamps);
        
        // For detailed overtime chart
        processReferrerTimestamps(data.clicks);
      } else {
        console.warn("No detailed click data found in response.");
        // Do NOT fall back to dummy data
        setTimeSeriesData(null);
        setDetailedTimeSeriesData(null);
      }
    } catch (error) {
      console.error("Error fetching detailed clicks:", error);
      // Do NOT fall back to dummy data
      setTimeSeriesData(null);
      setDetailedTimeSeriesData(null);
    } finally {
      setLoadingOvertime(false);
    }
  };
  
  // Process timestamps by referrer for detailed overtime chart
  const processReferrerTimestamps = (clicks) => {
    if (!clicks || clicks.length === 0) {
      console.warn("No clicks to process for referrer timestamps");
      return;
    }
    
    // Group clicks by referrer and date
    const referrerData = {};
    
    clicks.forEach(click => {
      if (!click.clicked_at) return;
      
      const clickDate = new Date(click.clicked_at);
      const dateStr = clickDate.toISOString().split('T')[0];
      const referrer = click.referrer || "No Referrer";
      
      if (!referrerData[referrer]) {
        referrerData[referrer] = {};
      }
      
      if (!referrerData[referrer][dateStr]) {
        referrerData[referrer][dateStr] = 0;
      }
      
      referrerData[referrer][dateStr]++;
    });
    
    setClicksByReferrer(referrerData);
    
    // Find the date range for all clicks
    const allDates = new Set();
    clicks.forEach(click => {
      if (click.clicked_at) {
        const dateStr = new Date(click.clicked_at).toISOString().split('T')[0];
        allDates.add(dateStr);
      }
    });
    
    const sortedDates = Array.from(allDates).sort();
    
    // Create a continuous date range
    const dateRange = [];
    if (sortedDates.length > 0) {
      const firstDate = new Date(sortedDates[0]);
      const lastDate = new Date(sortedDates[sortedDates.length - 1]);
      
      const currentDate = new Date(firstDate);
      while (currentDate <= lastDate) {
        dateRange.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    
    // Create datasets for each referrer
    const datasets = Object.keys(referrerData).map((referrer, index) => {
      const data = dateRange.map(date => ({
        x: date,
        y: Math.round(Number(referrerData[referrer][date] || 0)) // Ensure integer values
      }));
      
      return {
        label: referrer,
        data: data,
        borderColor: getColor(index),
        backgroundColor: `${getColor(index)}20`,
        borderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 5,
        tension: 0.3,
        fill: false
      };
    });
    
    // Limit to top 8 referrers if there are too many
    let finalDatasets = datasets;
    if (datasets.length > 8) {
      // Sort by total clicks
      finalDatasets = datasets.sort((a, b) => {
        const aTotal = a.data.reduce((sum, point) => sum + point.y, 0);
        const bTotal = b.data.reduce((sum, point) => sum + point.y, 0);
        return bTotal - aTotal;
      }).slice(0, 8);
    }
    
    setDetailedTimeSeriesData({
      datasets: finalDatasets
    });
  };
  
  // Process timestamps into chart data
  const processClickTimestamps = (clickTimestamps) => {
    if (!clickTimestamps || clickTimestamps.length === 0) {
      console.warn("No timestamps to process");
      return;
    }
    
    const clickDates = {};
    
    // Group clicks by date
    clickTimestamps.forEach(timestamp => {
      // Format date as YYYY-MM-DD
      const clickDate = new Date(timestamp);
      const dateStr = clickDate.toISOString().split('T')[0];
      
      if (!clickDates[dateStr]) {
        clickDates[dateStr] = 0;
      }
      clickDates[dateStr]++;
    });
    
    setClicksByDate(clickDates);
    
    // Sort dates and prepare time series data
    const sortedDates = Object.keys(clickDates).sort();
    
    // Fill in missing dates between first and last click
    if (sortedDates.length > 0) {
      const firstDate = new Date(sortedDates[0]);
      const lastDate = new Date(sortedDates[sortedDates.length - 1]);
      
      const allDates = [];
      const currentDate = new Date(firstDate);
      
      while (currentDate <= lastDate) {
        const dateStr = currentDate.toISOString().split('T')[0];
        allDates.push(dateStr);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      
      // Create time series data with all dates
      const timeSeriesPoints = allDates.map(date => ({
        x: date,
        y: Math.round(Number(clickDates[date] || 0)) // Ensure integer value
      }));
      
      setTimeSeriesData({
        datasets: [{
          label: 'Clicks per Day',
          data: timeSeriesPoints,
          fill: true,
          backgroundColor: `${nordColors.frost2}40`, // 40 = 25% opacity
          borderColor: nordColors.frost2,
          tension: 0.3,
          pointBackgroundColor: nordColors.frost3,
          pointBorderColor: '#fff',
          pointRadius: 4,
          pointHoverRadius: 6
        }]
      });
    }
  };

  // Add a dedicated function to fetch link details
  const fetchLinkDetails = async () => {
    try {
      setLinkLoading(true);
      console.log("Fetching link details for ID:", id);
      
      // Try to fetch link details from API
      const response = await fetch(`/api/links/${id}`, {
        method: "GET",
        credentials: "include",
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log("Fetched link details (full response):", data);
        
        if (data && data.link) {
          console.log("Link object structure:", Object.keys(data.link));
          setLinkInfo(data.link);
        } else {
          console.warn("Link details not found in response");
          // Fall back to just using the ID
          setLinkInfo({
            short_url: id,  // Just store the ID/path
            id: id
          });
        }
      } else {
        console.error("Failed to fetch link details. HTTP status:", response.status);
        // Fall back to just using the ID
        setLinkInfo({
          short_url: id,  // Just store the ID/path
          id: id
        });
      }
    } catch (error) {
      console.error("Error setting link details:", error);
      // Fall back to just using the ID
      setLinkInfo({
        short_url: id,  // Just store the ID/path
        id: id
      });
    } finally {
      setLinkLoading(false);
    }
  };

  // Add useEffect to fetch link details if they are missing
  useEffect(() => {
    if (!linkInfo && !linkLoading) {
      fetchLinkDetails();
    }
  }, [linkInfo, linkLoading, id]);

  // Parse UTM parameters from string format
  useEffect(() => {
    // If linkInfo exists but UTM parameters are in a string format, parse them
    if (linkInfo) {
      console.log("Current linkInfo state:", linkInfo);
      
      // Look for any field that might contain "http://" or "https://" to find the original URL
      if (!linkInfo.original_url && !linkInfo.destination_url && !linkInfo.target_url && !linkInfo.long_url) {
        Object.entries(linkInfo).forEach(([key, value]) => {
          if (
            typeof value === 'string' && 
            (value.startsWith('http://') || value.startsWith('https://')) &&
            key !== 'short_url' && 
            !key.includes('utm') &&
            !key.includes('UTM')
          ) {
            console.log(`Found potential original URL in field ${key}:`, value);
            setLinkInfo(prevInfo => ({
              ...prevInfo,
              original_url: value
            }));
          }
        });
      }

      // Check for UTM parameters in various possible string formats
      const possibleUtmFields = ['utm_params', 'utm_parameters', 'utm_string', 'utm'];
      
      possibleUtmFields.forEach(field => {
        if (linkInfo[field] && typeof linkInfo[field] === 'string') {
          console.log(`Found UTM string in field ${field}:`, linkInfo[field]);
          try {
            // Try to extract params from format like: utm_referral=https://shortify.xxiii.tools
            const params = {};
            linkInfo[field].split('&').forEach(param => {
              const [key, value] = param.split('=');
              if (key && value) {
                params[key] = decodeURIComponent(value);
              }
            });
            
            // If we found any parameters, update linkInfo
            if (Object.keys(params).length > 0) {
              console.log("Parsed UTM parameters:", params);
              setLinkInfo(prevInfo => ({
                ...prevInfo,
                ...params
              }));
            }
          } catch (e) {
            console.error("Error parsing UTM string:", e);
          }
        }
      });
      
      // Look for any field that might be a UTM parameter
      Object.entries(linkInfo).forEach(([key, value]) => {
        // Convert to lowercase for case-insensitive comparison
        const lowerKey = key.toLowerCase();
        
        // Check if this looks like a UTM field but isn't in our standard list
        if (
          (lowerKey.includes('utm') || lowerKey.includes('param')) && 
          typeof value === 'string' &&
          !['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_referral'].includes(lowerKey)
        ) {
          console.log(`Found potential UTM parameter in field ${key}:`, value);
          
          // If it contains "=" it might be a key-value pair
          if (value.includes('=')) {
            try {
              const [utmKey, utmValue] = value.split('=');
              if (utmKey && utmValue) {
                console.log(`Extracted UTM parameter: ${utmKey} = ${utmValue}`);
                
                const newParams = {};
                newParams[utmKey] = utmValue;
                
                setLinkInfo(prevInfo => ({
                  ...prevInfo,
                  ...newParams
                }));
              }
            } catch (e) {
              console.error(`Error parsing UTM parameter from ${key}:`, e);
            }
          }
        }
      });
      
      // Special case for the exact format seen in the screenshot: "utm_referral=https://shortify.xxiii.tools"
      if (typeof linkInfo.UTM_Parameters === 'string') {
        console.log("Found UTM_Parameters string:", linkInfo.UTM_Parameters);
        
        try {
          // Check if it matches the format seen in the screenshot
          if (linkInfo.UTM_Parameters.includes('utm_referral=')) {
            const utmReferral = linkInfo.UTM_Parameters.split('=')[1];
            if (utmReferral) {
              console.log("Extracted utm_referral value:", utmReferral);
              setLinkInfo(prevInfo => ({
                ...prevInfo,
                utm_referral: utmReferral
              }));
            }
          }
        } catch (e) {
          console.error("Error parsing UTM_Parameters string:", e);
        }
      }
    }
  }, [linkInfo]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/api/stats/${id}`, {
          method: "GET",
          credentials: "include",
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched stats:", data);
          console.log("Stats data format:", JSON.stringify(data.stats[0], null, 2));
          console.log("Has clicked_at property:", data.stats && data.stats.length > 0 && data.stats[0].hasOwnProperty('clicked_at'));

          // Store link information if available
          if (data.link) {
            console.log("Link data from stats API:", data.link);
            // If we already have some link info, merge it with the new data
            if (linkInfo) {
              setLinkInfo({
                ...linkInfo,
                ...data.link
              });
            } else {
              setLinkInfo(data.link);
            }
          }

          // Additional attempt to parse UTM parameters if they exist in a different format
          if (data.url_params) {
            console.log("URL parameters found:", data.url_params);
            
            // If we already have linkInfo, merge the URL parameters into it
            if (linkInfo) {
              setLinkInfo(prevInfo => ({
                ...prevInfo,
                ...data.url_params
              }));
            }
          }
          
          // Check for "utm_referral" format from the screenshot
          if (data.utm_parameters) {
            console.log("UTM parameters found:", data.utm_parameters);
            
            if (linkInfo) {
              setLinkInfo(prevInfo => ({
                ...prevInfo,
                ...data.utm_parameters
              }));
            }
          }

          if (data.stats && data.stats.length > 0) {
            let totalClickCount = 0;
            let referrerData = [];
            let clickTimestamps = [];

            // Check if we have detailed click data or aggregated data
            const hasDetailedData = data.stats[0].hasOwnProperty('clicked_at');
            
            if (hasDetailedData) {
              // Detailed data format (each click has a timestamp)
              totalClickCount = data.stats.length;
              
              // Process referrers
              const referrerCounts = {};
              data.stats.forEach(click => {
                // Handle empty referrer case - label it as "Direct"
                const referrer = click.referrer ? click.referrer.trim() : "Direct (No Referrer)";
                if (!referrerCounts[referrer]) {
                  referrerCounts[referrer] = 0;
                }
                referrerCounts[referrer]++;
                
                // Store timestamp for time-series chart
                if (click.clicked_at) {
                  clickTimestamps.push(click.clicked_at);
                }
              });
              
              // Convert to array format
              referrerData = Object.entries(referrerCounts)
                .map(([referrer, count]) => ({ referrer, count }))
                .sort((a, b) => b.count - a.count);
            } else {
              // Aggregated data format (pre-counted by referrer)
              referrerData = [...data.stats];
              
              // Handle empty referrers
              referrerData = referrerData.map(item => {
                if (!item.referrer || item.referrer.trim() === '') {
                  return { ...item, referrer: "Direct (No Referrer)" };
                }
                return item;
              });
              
              totalClickCount = referrerData.reduce((sum, entry) => sum + Number(entry.count), 0);
              
              // For time-series, we need to request additional data if needed
              // This implementation assumes timestamps aren't available in aggregated format
            }
            
            setTotalClicks(totalClickCount);
            
            // VERY IMPORTANT DEBUG: Log every single referrer we get from the API
            console.log("ALL REFERRERS FROM API:");
            referrerData.forEach(entry => {
              console.log(`Referrer: ${entry.referrer}, Count: ${entry.count}, Type: ${typeof entry.count}`);
            });
            
            // Prepare data for referrer charts (bar and pie)
            // Group smaller referrers into "Other" category if there are too many
            let processedReferrerData = [...referrerData];
            
            // Debug log to see what's in the data before processing
            console.log("Raw referrer data before filtering:", processedReferrerData);
            
            // Remove any referrers with less than 1 click (should be at least 1 if they appear in stats)
            processedReferrerData = processedReferrerData.filter(entry => {
              // Ensure count is treated as a number and is at least 1
              const numericCount = Number(entry.count);
              if (numericCount < 1) {
                console.warn(`Found referrer with suspiciously low count: ${entry.referrer} (${numericCount})`);
              }
              // We should ALWAYS have clicks >= 1, otherwise it's likely a bug
              return numericCount >= 1;
            });
            
            // *** NO NEED TO FILTER OUT/COMBINE ENTRIES ***
            // Let's show all referrers always
            /*
            if (referrerData.length > 8) {
              // Sort by count descending
              processedReferrerData.sort((a, b) => b.count - a.count);
              
              // Take top 7 referrers
              const topReferrers = processedReferrerData.slice(0, 7);
              
              // Combine the rest into "Other"
              const otherReferrers = processedReferrerData.slice(7);
              const otherCount = otherReferrers.reduce((sum, entry) => sum + parseInt(entry.count), 0);
              
              // Add "Other" category if there's anything to group
              if (otherCount > 0) {
                processedReferrerData = [
                  ...topReferrers,
                  { referrer: "Other", count: otherCount }
                ];
              } else {
                processedReferrerData = topReferrers;
              }
            }
            */
            
            // Sort by count descending always
            processedReferrerData.sort((a, b) => Number(b.count) - Number(a.count));
            
            // Ensure all counts are integers
            processedReferrerData = processedReferrerData.map(entry => ({
              ...entry,
              count: Number(entry.count) // Just convert to Number, no need for rounding integers
            }));
            
            // Final list of what we're showing
            console.log("FINAL DATA TO DISPLAY:", processedReferrerData);
            
            const labels = processedReferrerData.map(entry => entry.referrer);
            const counts = processedReferrerData.map(entry => entry.count);
            
            // Generate background colors for each bar/segment
            const backgroundColors = processedReferrerData.map((_, index) => getColor(index, 0.7));
            const borderColors = processedReferrerData.map((_, index) => getColor(index));

            setChartData({
              labels,
              datasets: [
                {
                  label: "Number of Clicks",
                  data: counts,
                  backgroundColor: backgroundColors,
                  borderColor: borderColors,
                  borderWidth: 1,
                  hoverBackgroundColor: processedReferrerData.map((_, index) => getColor(index, 0.9)),
                  borderRadius: 4,
                  barThickness: processedReferrerData.length === 1 ? 100 : 30, // Fixed thickness for consistency
                  maxBarThickness: 50,
                  minBarLength: 5 // Ensure even small values have minimal visibility
                },
              ],
            });

            // Process time series data if we have timestamps
            if (clickTimestamps.length > 0) {
              processClickTimestamps(clickTimestamps);
            } else {
              // If our original endpoint doesn't provide timestamps,
              // we'll set timeSeriesData to null and fetch it on demand when overtime chart is selected
              setTimeSeriesData(null);
            }
          } else {
            console.warn("No statistics data available.");
            setChartData(null);
            setTimeSeriesData(null);
          }
        } else {
          console.error("Failed to fetch stats. HTTP status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [id]);

  // When overtime chart or detailed overtime chart is selected, fetch detailed data if needed
  useEffect(() => {
    if ((selectedChart === "overtime" || selectedChart === "overtime-detailed") && 
        !timeSeriesData && !detailedTimeSeriesData && !loadingOvertime) {
      fetchDetailedClicks();
    }
  }, [selectedChart, timeSeriesData, detailedTimeSeriesData, loadingOvertime, id]);

  // Function to generate and download CSV data for time series
  const generateTimeSeriesCSV = () => {
    if (!timeSeriesData || !timeSeriesData.datasets || !timeSeriesData.datasets[0].data) {
      console.error("No time series data available to download");
      return;
    }
    
    // CSV Header
    let csvContent = "Date,Clicks\n";
    
    // Add data rows
    timeSeriesData.datasets[0].data.forEach(point => {
      const formattedDate = new Date(point.x).toISOString().split('T')[0];
      csvContent += `${formattedDate},${point.y}\n`;
    });
    
    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    // Create a temporary link and trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `clicks-over-time-${id}.csv`);
    document.body.appendChild(link);
    link.click();
    
    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);
  };

  // Function to generate and download CSV for referrer stats
  const generateReferrerStatsCSV = () => {
    if (!chartData || !chartData.labels || !chartData.datasets) {
      console.error("No chart data available to download");
      return;
    }
    
    // CSV Header
    let csvContent = "Referrer,Clicks,Percentage\n";
    
    // Add data rows
    chartData.labels.forEach((label, index) => {
      const clicks = chartData.datasets[0].data[index];
      const percentage = ((clicks / totalClicks) * 100).toFixed(2);
      csvContent += `"${label}",${clicks},${percentage}%\n`;
    });
    
    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    // Create a temporary link and trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `referrer-stats-${id}.csv`);
    document.body.appendChild(link);
    link.click();
    
    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);
  };

  // Function to generate and download CSV data for detailed time series
  const generateDetailedTimeSeriesCSV = () => {
    if (!detailedTimeSeriesData || !detailedTimeSeriesData.datasets) {
      console.error("No detailed time series data available to download");
      return;
    }
    
    // Get all dates and referrers
    const allDates = new Set();
    const referrers = [];
    
    detailedTimeSeriesData.datasets.forEach(dataset => {
      referrers.push(dataset.label);
      dataset.data.forEach(point => {
        allDates.add(point.x);
      });
    });
    
    const sortedDates = Array.from(allDates).sort();
    
    // CSV Header - Date and all referrers
    let csvContent = "Date," + referrers.join(",") + "\n";
    
    // Add data rows
    sortedDates.forEach(date => {
      const row = [date];
      
      // Add count for each referrer on this date
      referrers.forEach(referrer => {
        const dataset = detailedTimeSeriesData.datasets.find(d => d.label === referrer);
        const point = dataset.data.find(p => p.x === date);
        row.push(point ? point.y : 0);
      });
      
      csvContent += row.join(",") + "\n";
    });
    
    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    // Create a temporary link and trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `detailed-clicks-over-time-${id}.csv`);
    document.body.appendChild(link);
    link.click();
    
    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 dark:text-nord-snowStorm1 text-nord-polarNight1">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-12 h-12 border-4 border-nord-frost2 border-t-transparent rounded-full animate-spin mb-3"></div>
          <p>Loading statistics...</p>
        </div>
      </div>
    );
  }

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: chartData && chartData.labels.length > 5 ? 'y' : 'x', // Use horizontal bars for many referrers
    plugins: {
      legend: { 
        position: "top",
        align: "center",
        labels: {
          color: nordColors.textColor,
          font: {
            size: 12
          },
          boxWidth: 15,
          padding: 15
        }
      },
      title: { 
        display: true, 
        text: "Click Statistics by Referrer",
        color: nordColors.textColor,
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      tooltip: {
        backgroundColor: theme === 'dark' ? "#3b4252" : "#e5e9f0",
        titleColor: nordColors.textColor,
        bodyColor: nordColors.textColor,
        borderColor: nordColors.frost3,
        borderWidth: 1,
        callbacks: {
          label: (context) => {
            const value = Math.round(context.raw); // Ensure integer value
            const percentage = ((value / totalClicks) * 100).toFixed(1);
            return `Clicks: ${value} (${percentage}%)`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: nordColors.gridColor
        },
        ticks: {
          color: nordColors.textColor,
          precision: 0, // Only show whole numbers
          // Important: Make sure the font size is explicitly set for the y-axis
          font: {
            size: 12 // Adjusted for better readability
          },
          // Make sure this axis can display the labels
          maxRotation: 0,
          minRotation: 0,
          mirror: false,
          // Force display all labels
          autoSkip: false,
          padding: 10 // Add padding between labels and axis
        }
      },
      x: {
        beginAtZero: true, // Ensure we start at 0
        grid: {
          color: nordColors.gridColor
        },
        ticks: {
          color: nordColors.textColor,
          maxRotation: 45,
          minRotation: 0,
          precision: 0, // Only display whole numbers on ticks
          stepSize: 1,  // Force step size to 1 for clear integer display
          callback: function(value) {
            return Math.round(value); // Ensure integer display on axis
          }
        }
      }
    },
    animation: {
      duration: 2000,
      easing: 'easeOutQuart'
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 10
      }
    },
    // Ensure there's enough room between bars for readability
    barPercentage: 0.8,
    categoryPercentage: 0.9
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          color: nordColors.textColor,
          font: {
            size: 11
          },
          boxWidth: 15,
          padding: 15
        }
      },
      title: { 
        display: true, 
        text: "Click Distribution by Referrer",
        color: nordColors.textColor,
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      tooltip: {
        backgroundColor: theme === 'dark' ? "#3b4252" : "#e5e9f0",
        titleColor: nordColors.textColor,
        bodyColor: nordColors.textColor,
        borderColor: nordColors.frost3,
        borderWidth: 1,
        callbacks: {
          label: (context) => {
            const value = Math.round(context.raw); // Ensure integer value
            const percentage = ((value / totalClicks) * 100).toFixed(1);
            return `Clicks: ${value} (${percentage}%)`;
          }
        }
      }
    },
    animation: {
      animateRotate: true,
      animateScale: true
    }
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: nordColors.textColor,
          font: {
            size: 12
          }
        }
      },
      title: { 
        display: true, 
        text: "Clicks Over Time",
        color: nordColors.textColor,
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      tooltip: {
        backgroundColor: theme === 'dark' ? "#3b4252" : "#e5e9f0",
        titleColor: nordColors.textColor,
        bodyColor: nordColors.textColor,
        borderColor: nordColors.frost3,
        borderWidth: 1,
        callbacks: {
          title: (context) => {
            // Format the date nicely for display
            const date = new Date(context[0].parsed.x);
            return date.toLocaleDateString(undefined, { 
              year: 'numeric', 
              month: 'short', 
              day: 'numeric' 
            });
          },
          label: (context) => {
            return `Clicks: ${Math.round(context.parsed.y)}`; // Ensure integer display
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: nordColors.gridColor
        },
        ticks: {
          color: nordColors.textColor,
          precision: 0,
          stepSize: 1,
          callback: function(value) {
            return Math.round(value); // Ensure integer display on axis
          }
        },
        title: {
          display: true,
          text: 'Number of Clicks',
          color: nordColors.textColor
        }
      },
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
          displayFormats: {
            day: 'MMM d'
          }
        },
        grid: {
          color: nordColors.gridColor
        },
        ticks: {
          color: nordColors.textColor,
          maxRotation: 45,
          autoSkip: true,
          maxTicksLimit: 10
        },
        title: {
          display: true,
          text: 'Date',
          color: nordColors.textColor
        }
      }
    },
    animation: {
      duration: 2000,
      easing: 'easeOutQuart'
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    elements: {
      line: {
        borderWidth: 2
      }
    }
  };

  const detailedLineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: nordColors.textColor,
          font: {
            size: 12
          },
          boxWidth: 15,
          padding: 10
        }
      },
      title: { 
        display: true, 
        text: "Clicks Over Time by Referrer",
        color: nordColors.textColor,
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      tooltip: {
        backgroundColor: theme === 'dark' ? "#3b4252" : "#e5e9f0",
        titleColor: nordColors.textColor,
        bodyColor: nordColors.textColor,
        borderColor: nordColors.frost3,
        borderWidth: 1,
        callbacks: {
          title: (context) => {
            const date = new Date(context[0].parsed.x);
            return date.toLocaleDateString(undefined, { 
              year: 'numeric', 
              month: 'short', 
              day: 'numeric' 
            });
          },
          label: (context) => {
            return `${context.dataset.label}: ${Math.round(context.parsed.y)} clicks`; // Ensure integer display
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: nordColors.gridColor
        },
        ticks: {
          color: nordColors.textColor,
          precision: 0,
          stepSize: 1,
          callback: function(value) {
            return Math.round(value); // Ensure integer display on axis
          }
        },
        title: {
          display: true,
          text: 'Number of Clicks',
          color: nordColors.textColor
        }
      },
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
          displayFormats: {
            day: 'MMM d'
          }
        },
        grid: {
          color: nordColors.gridColor
        },
        ticks: {
          color: nordColors.textColor,
          maxRotation: 45,
          autoSkip: true,
          maxTicksLimit: 10
        },
        title: {
          display: true,
          text: 'Date',
          color: nordColors.textColor
        }
      }
    },
    animation: {
      duration: 2000,
      easing: 'easeOutQuart'
    },
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    elements: {
      line: {
        borderWidth: 2
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <div>
          <h1 className="text-2xl font-bold dark:text-nord-snowStorm1 text-nord-polarNight1">
            Link Statistics
          </h1>
        </div>
        {chartData && (
          <button
            onClick={() => navigate(-1)}
            className="btn-primary text-sm mt-2 md:mt-0"
          >
            ← Back to Links
          </button>
        )}
      </div>
      
      {!chartData ? (
        <div className="nord-card p-8 text-center">
          <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-4">
            No click data available for this link yet.
          </p>
          <button
            onClick={() => navigate(-1)}
            className="btn-primary text-sm"
          >
            ← Back to Links
          </button>
        </div>
      ) : (
        <div className="nord-card p-6">
          {linkInfo && (
            <div className="mb-6 p-4 dark:bg-nord-polarNight3 bg-nord-snowStorm1 rounded-md">
              <h2 className="text-lg font-semibold mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
                Link Details
              </h2>
              <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-2">
                <strong>URL:</strong> https://short.xxiii.tools/{linkInfo.short_url}
              </p>
              {/* Check for UTM parameters in different formats */}
              {(linkInfo.utm_source || linkInfo.utm_medium || linkInfo.utm_campaign || 
                linkInfo.utm_term || linkInfo.utm_content || linkInfo.utm_referral ||
                linkInfo.UTM_Parameters) && (
                <div className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-2">
                  <strong>UTM Parameters:</strong>
                  <ul className="pl-5 mt-1 list-disc">
                    {linkInfo.utm_source && <li>Source: {linkInfo.utm_source}</li>}
                    {linkInfo.utm_medium && <li>Medium: {linkInfo.utm_medium}</li>}
                    {linkInfo.utm_campaign && <li>Campaign: {linkInfo.utm_campaign}</li>}
                    {linkInfo.utm_term && <li>Term: {linkInfo.utm_term}</li>}
                    {linkInfo.utm_content && <li>Content: {linkInfo.utm_content}</li>}
                    {linkInfo.utm_referral && <li>Referral: {linkInfo.utm_referral}</li>}
                    {/* Display the raw UTM parameters string if nothing else is found */}
                    {!linkInfo.utm_source && !linkInfo.utm_medium && !linkInfo.utm_campaign && 
                     !linkInfo.utm_term && !linkInfo.utm_content && !linkInfo.utm_referral && 
                     linkInfo.UTM_Parameters && <li>{linkInfo.UTM_Parameters}</li>}
                  </ul>
                </div>
              )}
              {linkInfo.created_at && (
                <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-2">
                  <strong>Created:</strong> {new Date(linkInfo.created_at).toLocaleString()}
                </p>
              )}
              {linkInfo.expiry_date && (
                <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-2">
                  <strong>Expires:</strong> {new Date(linkInfo.expiry_date).toLocaleString()}
                </p>
              )}
              {/* Display any custom fields or additional metadata */}
              {linkInfo.custom_metadata && Object.keys(linkInfo.custom_metadata).length > 0 && (
                <div className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-2">
                  <strong>Additional Metadata:</strong>
                  <ul className="pl-5 mt-1 list-disc">
                    {Object.entries(linkInfo.custom_metadata).map(([key, value]) => (
                      <li key={key}>{key}: {value}</li>
                    ))}
                  </ul>
                </div>
              )}
              <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mt-2">
                <strong>Total Clicks:</strong> {totalClicks}
              </p>
            </div>
          )}
          
          <div className="mb-4 flex justify-end">
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <button
                type="button"
                onClick={() => setSelectedChart("bar")}
                className={`px-4 py-2 text-sm font-medium rounded-l-md ${
                  selectedChart === "bar"
                    ? "dark:bg-nord-frost2 bg-nord-frost4 text-white"
                    : "dark:bg-nord-polarNight3 bg-nord-snowStorm1 dark:text-nord-snowStorm1 text-nord-polarNight1"
                }`}
              >
                Bar Chart
              </button>
              <button
                type="button"
                onClick={() => setSelectedChart("pie")}
                className={`px-4 py-2 text-sm font-medium ${
                  selectedChart === "pie"
                    ? "dark:bg-nord-frost2 bg-nord-frost4 text-white"
                    : "dark:bg-nord-polarNight3 bg-nord-snowStorm1 dark:text-nord-snowStorm1 text-nord-polarNight1"
                }`}
              >
                Pie Chart
              </button>
              <button
                type="button"
                onClick={() => setSelectedChart("overtime")}
                className={`px-4 py-2 text-sm font-medium ${
                  selectedChart === "overtime"
                    ? "dark:bg-nord-frost2 bg-nord-frost4 text-white"
                    : "dark:bg-nord-polarNight3 bg-nord-snowStorm1 dark:text-nord-snowStorm1 text-nord-polarNight1"
                }`}
              >
                Overtime Chart
              </button>
              <button
                type="button"
                onClick={() => setSelectedChart("overtime-detailed")}
                className={`px-4 py-2 text-sm font-medium rounded-r-md ${
                  selectedChart === "overtime-detailed"
                    ? "dark:bg-nord-frost2 bg-nord-frost4 text-white"
                    : "dark:bg-nord-polarNight3 bg-nord-snowStorm1 dark:text-nord-snowStorm1 text-nord-polarNight1"
                }`}
              >
                Overtime Detailed
              </button>
            </div>
          </div>
          
          <div className="mb-8 h-[500px] flex justify-center items-center">
            {selectedChart === "bar" ? (
              <div className="w-full max-w-3xl px-12">
                <Bar key={`bar-chart-${id}`} data={chartData} options={barChartOptions} />
              </div>
            ) : selectedChart === "pie" ? (
              <div className="w-full max-w-md mx-auto">
                <Pie key={`pie-chart-${id}`} data={chartData} options={pieChartOptions} />
              </div>
            ) : selectedChart === "overtime" ? (
              loadingOvertime ? (
                <div className="flex h-full items-center justify-center">
                  <div className="animate-pulse flex flex-col items-center">
                    <div className="w-12 h-12 border-4 border-nord-frost2 border-t-transparent rounded-full animate-spin mb-3"></div>
                    <p className="dark:text-nord-snowStorm1 text-nord-polarNight1">Loading overtime data...</p>
                  </div>
                </div>
              ) : timeSeriesData ? (
                <div className="w-full max-w-3xl">
                  <Line key={`overtime-chart-${id}`} data={timeSeriesData} options={lineChartOptions} />
                </div>
              ) : (
                <div className="flex h-full flex-col items-center justify-center">
                  <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-4">
                    Failed to load time-based data. This may require a backend update or check API endpoint.
                  </p>
                  <button 
                    onClick={fetchDetailedClicks} // Allow retry
                    className="btn-primary"
                  >
                    Retry Loading Overtime Data
                  </button>
                </div>
              )
            ) : selectedChart === "overtime-detailed" ? (
              loadingOvertime ? (
                <div className="flex h-full items-center justify-center">
                  <div className="animate-pulse flex flex-col items-center">
                    <div className="w-12 h-12 border-4 border-nord-frost2 border-t-transparent rounded-full animate-spin mb-3"></div>
                    <p className="dark:text-nord-snowStorm1 text-nord-polarNight1">Loading detailed overtime data...</p>
                  </div>
                </div>
              ) : detailedTimeSeriesData ? (
                <div className="w-full max-w-3xl">
                  <Line key={`detailed-overtime-chart-${id}`} data={detailedTimeSeriesData} options={detailedLineChartOptions} />
                </div>
              ) : (
                <div className="flex h-full flex-col items-center justify-center">
                  <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 mb-4">
                    Failed to load detailed time-based data. Check API endpoint or backend logs.
                  </p>
                  <button 
                    onClick={fetchDetailedClicks} // Allow retry
                    className="btn-primary"
                  >
                    Retry Loading Detailed Data
                  </button>
                </div>
              )
            ) : (
              // Default case for any other chart types
              <div className="flex h-full flex-col items-center justify-center">
                <p className="dark:text-nord-snowStorm1 text-nord-polarNight1">
                  Select a chart type to view statistics
                </p>
              </div>
            )}
          </div>
          
          <div className="flex flex-wrap gap-4">
            {selectedChart === "overtime" && timeSeriesData ? (
              <button
                onClick={generateTimeSeriesCSV}
                className="btn-secondary"
              >
                Download Time Series CSV
              </button>
            ) : selectedChart === "overtime-detailed" && detailedTimeSeriesData ? (
              <button
                onClick={generateDetailedTimeSeriesCSV}
                className="btn-secondary"
              >
                Download Detailed Time Series CSV
              </button>
            ) : (
              <button
                onClick={generateReferrerStatsCSV}
                className="btn-secondary"
              >
                Download Referrer Stats
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default StatisticsPage;
