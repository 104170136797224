import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        try {
            const response = await fetch("/api/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                // Login successful, now fetch user details for the context
                const statusResponse = await fetch("/api/auth/status", { credentials: 'include' });
                if (statusResponse.ok) {
                    const statusData = await statusResponse.json();
                    if (statusData.isLoggedIn && statusData.user) {
                        // Call the login function from AuthContext with user data
                        login(statusData.user);
                        // Navigate to the manage URLs page (or appropriate dashboard)
                        navigate("/manage"); 
                    } else {
                         // This case should ideally not happen if login API succeeded
                         setError("Login succeeded but failed to fetch user details.");
                    }
                } else {
                     setError("Login succeeded but failed to verify status.");
                }
            } else {
                const data = await response.json();
                setError(data.message || "Login failed. Please check your credentials.");
            }
        } catch (error) {
            console.error("Login error:", error);
            setError("An error occurred during login. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-[calc(100vh-8rem)]">
            <div className="w-full max-w-md nord-card p-8">
                <h1 className="text-2xl font-bold mb-6 text-center dark:text-nord-snowStorm1 text-nord-polarNight1">
                    Log in to Your Account
                </h1>
                
                {error && (
                    <div className="bg-nord-aurora1 bg-opacity-20 text-nord-aurora1 p-3 rounded-md mb-4">
                        {error}
                    </div>
                )}
                
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="username" className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-2 border rounded 
                                      dark:bg-nord-polarNight3 dark:border-nord-polarNight4 dark:text-nord-snowStorm1
                                      bg-nord-snowStorm2 border-nord-snowStorm1 text-nord-polarNight1
                                      focus:outline-none focus:ring-2 focus:ring-nord-frost2"
                            required
                        />
                    </div>
                    
                    <div className="mb-6">
                        <label htmlFor="password" className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border rounded 
                                      dark:bg-nord-polarNight3 dark:border-nord-polarNight4 dark:text-nord-snowStorm1
                                      bg-nord-snowStorm2 border-nord-snowStorm1 text-nord-polarNight1
                                      focus:outline-none focus:ring-2 focus:ring-nord-frost2"
                            required
                        />
                    </div>
                    
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full btn-primary ${loading ? "opacity-70 cursor-not-allowed" : ""}`}
                    >
                        {loading ? "Logging in..." : "Log In"}
                    </button>
                    
                    <div className="mt-4 text-center dark:text-nord-snowStorm1 text-nord-polarNight1">
                        Don't have an account? 
                        <Link to="/register" className="ml-1 dark:text-nord-frost2 text-nord-frost4 hover:underline">
                            Register here
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;

