import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "../context/ThemeContext";
import { FaSun, FaMoon } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";

function Navbar() {
  const { user, isLoggedIn, logout } = useAuth();
  const { theme, toggleTheme } = useTheme();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <nav className="transition-colors duration-200
                  dark:bg-nord-polarNight2 bg-nord-snowStorm1
                  dark:text-nord-snowStorm2 text-nord-polarNight1
                  shadow-md">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between p-4">
        <Link to={isLoggedIn ? "/manage" : "/"} className="flex items-center space-x-3">
          <span className="self-center text-2xl font-semibold whitespace-nowrap 
                         dark:text-nord-frost2 text-nord-frost4">
            Shortify {user && user.account_type === 'company_admin' && "(Admin)"} {user && user.account_type === 'subuser' && "(Subuser)"}
          </span>
        </Link>
        
        <div className="flex items-center space-x-4">
          <ul className="flex space-x-4">
            {isLoggedIn ? (
              <>
                <li>
                  <Link to="/shorten" className="hover:text-nord-frost2 transition-colors">
                    Shorten
                  </Link>
                </li>
                <li>
                  <Link to="/manage" className="hover:text-nord-frost2 transition-colors">
                    Manage URLs
                  </Link>
                </li>
                {(user?.account_type === 'personal' || user?.account_type === 'company_admin') && (
                  <li>
                    <Link to="/domains" className="hover:text-nord-frost2 transition-colors">
                      Custom Domains
                    </Link>
                  </li>
                )}
                {user?.account_type === 'company_admin' && (
                  <li>
                    <Link to="/company/subusers" className="hover:text-nord-frost2 transition-colors">
                      Manage Subusers
                    </Link>
                  </li>
                )}
                <li>
                  <button
                    onClick={handleLogout}
                    className="hover:text-nord-frost2 transition-colors"
                  >
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/" className="hover:text-nord-frost2 transition-colors">
                    Login
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="hover:text-nord-frost2 transition-colors">
                    Register
                  </Link>
                </li>
              </>
            )}
          </ul>
          
          {/* Theme toggle button */}
          <button
            onClick={toggleTheme}
            className="p-2 rounded-full hover:bg-opacity-20
                     dark:hover:bg-nord-polarNight4 hover:bg-nord-snowStorm2
                     transition-colors focus:outline-none"
            aria-label={`Switch to ${theme === 'dark' ? 'light' : 'dark'} mode`}
          >
            {theme === 'dark' ? (
              <FaSun className="text-nord-aurora3 text-xl" />
            ) : (
              <FaMoon className="text-nord-frost4 text-xl" />
            )}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
