// frontend/src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ShortenPage from "./pages/ShortenPage";
import ManageUrlsPage from "./pages/ManageUrlsPage";
import StatisticsPage from "./pages/StatisticsPage";
import DomainsPage from "./pages/DomainsPage";
import ManageSubusersPage from "./pages/ManageSubusersPage";
import { ThemeProvider } from "./context/ThemeContext";
import { AuthProvider, useAuth } from "./context/AuthContext";
import "./App.css";

// Protected Route component
const ProtectedRoute = ({ element }) => {
  const { isLoggedIn, isLoading } = useAuth();
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-12 h-12 border-4 border-nord-frost2 border-t-transparent rounded-full animate-spin mb-3"></div>
          <p>Loading...</p>
        </div>
      </div>
    );
  }
  
  return isLoggedIn ? element : <Navigate to="/" replace />;
};

// Root route handler - redirects to ManageUrlsPage if logged in, otherwise LoginPage
const RootRoute = () => {
  const { isLoggedIn, isLoading } = useAuth();
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-12 h-12 border-4 border-nord-frost2 border-t-transparent rounded-full animate-spin mb-3"></div>
          <p>Loading...</p>
        </div>
      </div>
    );
  }
  
  return isLoggedIn ? <Navigate to="/manage" replace /> : <LoginPage />;
};

function AppContent() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen transition-colors duration-200
                    dark:bg-nord-polarNight1 dark:text-nord-snowStorm3
                    bg-nord-snowStorm3 text-nord-polarNight1">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-6">
          <Routes>
            <Route path="/" element={<RootRoute />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/shorten" element={<ProtectedRoute element={<ShortenPage />} />} />
            <Route path="/manage" element={<ProtectedRoute element={<ManageUrlsPage />} />} />
            <Route path="/statistics/:id" element={<ProtectedRoute element={<StatisticsPage />} />} />
            <Route path="/domains" element={<ProtectedRoute element={<DomainsPage />} />} />
            <Route path="/company/subusers" element={<ProtectedRoute element={<ManageSubusersPage />} />} />
          </Routes>
        </main>
        <footer className="py-4 text-center dark:bg-nord-polarNight2 bg-nord-snowStorm1
                         dark:text-nord-snowStorm1 text-nord-polarNight3">
          <p>© 2025 Shortify - URL Shortener</p>
        </footer>
      </div>
    </Router>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
