import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

function RegisterPage() {
    // Test comment to check git commit
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [accountType, setAccountType] = useState("user");
    const navigate = useNavigate();

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const response = await fetch("/api/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password, account_type: accountType }),
        });

        if (response.ok) {
            alert("Registration successful!");
            navigate("/"); // Redirect to login page after successful registration
        } else {
            const data = await response.json();
            alert(data.error || "Registration failed");
        }
    };

    return (
        <div className="flex justify-center items-center py-16">
            <div className="max-w-md w-full nord-card p-8">
                <h2 className="text-2xl font-bold mb-6 text-center dark:text-nord-snowStorm1 text-nord-polarNight1">
                    Register
                </h2>
                <div className="space-y-4">
                    <div>
                        <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
                            Username
                        </label>
                        <input
                            type="text"
                            placeholder="Choose a username"
                            className="nord-input w-full"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
                            Password
                        </label>
                        <input
                            type="password"
                            placeholder="Choose a password"
                            className="nord-input w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block mb-2 dark:text-nord-snowStorm1 text-nord-polarNight1">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            placeholder="Confirm your password"
                            className="nord-input w-full"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center mt-4">
                         <input
                             id="companyAccountCheckbox"
                             type="checkbox"
                             className="h-4 w-4 dark:accent-nord-frost2 accent-nord-frost4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                             checked={accountType === 'company_admin'}
                             onChange={(e) => setAccountType(e.target.checked ? 'company_admin' : 'user')}
                         />
                         <label htmlFor="companyAccountCheckbox" className="ml-2 block text-sm dark:text-nord-snowStorm1 text-nord-polarNight1">
                             Register as a Company Account
                         </label>
                     </div>
                    <button
                        className="btn-primary w-full mt-6"
                        onClick={handleRegister}
                    >
                        Register
                    </button>
                    <div className="mt-4 text-center dark:text-nord-snowStorm1 text-nord-polarNight1">
                        Already have an account?
                        <Link to="/" className="ml-1 dark:text-nord-frost2 text-nord-frost4 hover:underline">
                            Login here
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;

