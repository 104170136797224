import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UrlsTable from "../components/UrlsTable";

function ManageUrlsPage() {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableLinks, setEditableLinks] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await fetch("/api/manage", {
          method: "GET",
          credentials: "include", // Include session cookies
        });
        
        if (response.ok) {
          const data = await response.json();
          setLinks(data.links);
          // Initialize editable links state
          const initialEditableLinks = {};
          data.links.forEach((link) => {
            initialEditableLinks[link.id] = {
              original_url: link.original_url,
              utm_parameters: link.utm_parameters || "",
            };
          });
          setEditableLinks(initialEditableLinks);
        } else if (response.status === 401) {
          // If unauthorized, redirect to login page instead of showing alert
          navigate("/");
          return; // Stop execution after redirect
        } else {
          console.error("Error fetching URLs:", response.status);
        }
      } catch (error) {
        console.error("Error fetching URLs:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchLinks();
  }, [navigate]); // Add navigate to dependency array

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this URL?");
    if (!confirmDelete) return;

    const response = await fetch(`/api/manage/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
    if (response.ok) {
      alert("URL deleted successfully.");
      setLinks(links.filter((link) => link.id !== id));
    } else {
      alert("Failed to delete URL.");
    }
  };

  const handleSave = async (id) => {
    const updatedUrl = editableLinks[id].original_url.trim();
    let updatedUtmParams = editableLinks[id].utm_parameters.trim();

    try {
      // Parse UTM parameters to validate and then serialize them as JSON
      const utmParamsObject = {};
      updatedUtmParams.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        if (key && value) {
          utmParamsObject[key.trim()] = value.trim();
        }
      });
      updatedUtmParams = JSON.stringify(utmParamsObject);

      const response = await fetch(`/api/manage/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          original_url: updatedUrl,
          utm_parameters: updatedUtmParams,
        }),
      });

      if (response.ok) {
        alert("URL updated successfully.");
        const updatedLinks = links.map((link) =>
          link.id === id
            ? { ...link, original_url: updatedUrl, utm_parameters: updatedUtmParams }
            : link
        );
        setLinks(updatedLinks);
      } else {
        alert("Failed to update URL.");
      }
    } catch (error) {
      alert("An error occurred while saving.");
      console.error(error);
    }
  };

  const handleStatistics = (id) => {
    navigate(`/statistics/${id}`);
  };

  const handleEditChange = (id, field, value) => {
    setEditableLinks((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64 dark:text-nord-snowStorm1 text-nord-polarNight1">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6 dark:text-nord-snowStorm1 text-nord-polarNight1">
        URLs
      </h1>
      {links.length === 0 ? (
        <p className="dark:text-nord-snowStorm1 text-nord-polarNight1 text-center py-8">
          No URLs found. Create a shortened URL first.
        </p>
      ) : (
        <div className="nord-card">
          <UrlsTable
            links={links}
            editableLinks={editableLinks}
            onSave={handleSave}
            onDelete={handleDelete}
            onStatistics={handleStatistics}
            onEditChange={handleEditChange}
          />
        </div>
      )}
    </div>
  );
}

export default ManageUrlsPage;
