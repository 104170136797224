import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '../context/ThemeContext';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  createTheme,
  ThemeProvider
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon
} from '@mui/icons-material';

const DomainsPage = () => {
  const navigate = useNavigate();
  const [domains, setDomains] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newDomain, setNewDomain] = useState('');
  const [editingDomain, setEditingDomain] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { theme } = useTheme();
  
  // Create MUI theme based on Nord
  const nordMuiTheme = createTheme({
    palette: {
      mode: theme === 'dark' ? 'dark' : 'light',
      primary: {
        main: theme === 'dark' ? '#88c0d0' : '#5e81ac', // frost2 in dark, frost4 in light
      },
      secondary: {
        main: theme === 'dark' ? '#81a1c1' : '#8fbcbb', // frost3 in dark, frost1 in light
      },
      error: {
        main: '#bf616a', // aurora1 (red)
      },
      success: {
        main: '#a3be8c', // aurora4 (green)
      },
      background: {
        default: theme === 'dark' ? '#2e3440' : '#eceff4', // polarNight1 or snowStorm3
        paper: theme === 'dark' ? '#3b4252' : '#e5e9f0', // polarNight2 or snowStorm2
      },
      text: {
        primary: theme === 'dark' ? '#eceff4' : '#2e3440', // snowStorm3 or polarNight1
        secondary: theme === 'dark' ? '#d8dee9' : '#4c566a', // snowStorm1 or polarNight4
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  });

  // Add an authorization check handler
  const handleAuthError = (error) => {
    if (error.response && error.response.status === 401) {
      navigate('/');
      return true;
    }
    return false;
  };
  
  // Fetch domains with auth check
  const fetchDomains = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/user/domains', { withCredentials: true });
      setDomains(response.data);
      setError(null);
    } catch (error) {
      if (!handleAuthError(error)) {
        setError('Failed to fetch domains. Please try again.');
        console.error('Error fetching domains:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  const handleAddDomain = async () => {
    try {
      setLoading(true);
      await axios.post('/api/user/domains', { domain: newDomain }, { withCredentials: true });
      setSuccess('Domain added successfully');
      setOpenDialog(false);
      setNewDomain('');
      fetchDomains();
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to add domain');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDomain = async () => {
    try {
      setLoading(true);
      await axios.put(`/api/user/domains/${editingDomain.id}`, 
        { domain: editingDomain.domain },
        { withCredentials: true }
      );
      setSuccess('Domain updated successfully');
      setOpenEditDialog(false);
      setEditingDomain(null);
      fetchDomains();
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update domain');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDomain = async (domainId) => {
    if (window.confirm('Are you sure you want to delete this domain?')) {
      try {
        setLoading(true);
        await axios.delete(`/api/user/domains/${domainId}`, { withCredentials: true });
        setSuccess('Domain deleted successfully');
        fetchDomains();
      } catch (err) {
        setError('Failed to delete domain');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGenerateCertificate = async (domainId) => {
    try {
      setLoading(true);
      await axios.post(`/api/user/domains/${domainId}/certificate`, {}, { withCredentials: true });
      setSuccess('Certificate generated successfully');
      fetchDomains();
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to generate certificate');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleString();
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6 dark:text-nord-snowStorm1 text-nord-polarNight1">
        Custom Domains
      </h1>
      
      <ThemeProvider theme={nordMuiTheme}>
        <Box className="nord-card p-6">
          <Box display="flex" justifyContent="flex-end" mb={3}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Add Domain
            </Button>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccess(null)}>
              {success}
            </Alert>
          )}

          {loading ? (
            <Box display="flex" justifyContent="center" mt={4} mb={4}>
              <CircularProgress />
            </Box>
          ) : domains.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1">
                No custom domains configured. Add your first domain to get started.
              </Typography>
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Domain</TableCell>
                    <TableCell>Certificate Status</TableCell>
                    <TableCell>Expires At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {domains.map((domain) => (
                    <TableRow key={domain.id}>
                      <TableCell>{domain.domain}</TableCell>
                      <TableCell>
                        {domain.has_certificate ? (
                          <Tooltip title="Certificate is valid">
                            <CheckCircleIcon color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="No certificate">
                            <ErrorIcon color="error" />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>{formatDate(domain.cert_expires_at)}</TableCell>
                      <TableCell>
                        <Tooltip title="Generate Certificate">
                          <IconButton
                            onClick={() => handleGenerateCertificate(domain.id)}
                            disabled={loading}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Domain">
                          <IconButton
                            onClick={() => {
                              setEditingDomain(domain);
                              setOpenEditDialog(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Domain">
                          <IconButton
                            onClick={() => handleDeleteDomain(domain.id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Add Domain Dialog */}
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>Add New Domain</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Domain"
                fullWidth
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
                placeholder="example.com"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              <Button onClick={handleAddDomain} variant="contained" disabled={loading}>
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Edit Domain Dialog */}
          <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
            <DialogTitle>Edit Domain</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Domain"
                fullWidth
                value={editingDomain?.domain || ''}
                onChange={(e) =>
                  setEditingDomain({ ...editingDomain, domain: e.target.value })
                }
                placeholder="your-domain.com"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
              <Button onClick={handleUpdateDomain} variant="contained" disabled={loading}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default DomainsPage; 