import React from "react";
import UrlRow from "./UrlRow";

const UrlsTable = ({ links, editableLinks, onSave, onDelete, onStatistics, onEditChange }) => {
  return (
    <table className="table-auto w-full border-collapse 
                     dark:border-nord-polarNight4 border-nord-frost1
                     transition-colors duration-200">
      <thead>
        <tr className="dark:bg-nord-polarNight3 bg-nord-snowStorm1">
          <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-left">Shortened URL</th>
          <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-left">Original URL</th>
          <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-left">UTM Parameters</th>
          <th className="dark:border-nord-polarNight4 border-nord-snowStorm1 p-3 text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        {links.map((link) => (
          <UrlRow
            key={link.id}
            link={link}
            editableLink={editableLinks[link.id] || {}}
            onSave={onSave}
            onDelete={onDelete}
            onStatistics={onStatistics}
            onEditChange={onEditChange}
          />
        ))}
      </tbody>
    </table>
  );
};

export default UrlsTable;
