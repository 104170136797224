import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, CircularProgress, Alert } from '@mui/material';
import { useTheme } from '../context/ThemeContext';

const QrCodeGenerator = ({ linkId, shortUrl }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCodeInfo, setQrCodeInfo] = useState(null);
  const [error, setError] = useState(null);
  const { theme } = useTheme();
  
  // Get Nord theme colors based on current theme
  const bgColor = theme === 'dark' ? '#3b4252' : '#e5e9f0'; // polarNight2 or snowStorm2
  const textColor = theme === 'dark' ? '#eceff4' : '#2e3440'; // snowStorm3 or polarNight1
  const borderColor = theme === 'dark' ? '#4c566a' : '#d8dee9'; // polarNight4 or snowStorm1

  useEffect(() => {
    if (open) {
      fetchQrCodeInfo();
    }
  }, [open]);

  const fetchQrCodeInfo = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/links/${linkId}/qrcode`);
      if (response.ok) {
        const data = await response.json();
        setQrCodeInfo(data);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to fetch QR code information');
      }
    } catch (err) {
      setError('Network error while fetching QR code information');
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const generateQrCode = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(`/api/links/${linkId}/qrcode`, {
        method: 'POST',
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setQrCodeInfo({
          ...qrCodeInfo,
          has_qr_code: true,
          qr_code_url: data.qr_code_url
        });
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to generate QR code');
      }
    } catch (err) {
      console.error("QR code generation error:", err);
      setError('Network error while generating QR code');
    } finally {
      setLoading(false);
    }
  };

  const deleteQrCode = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/links/${linkId}/qrcode`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (response.ok) {
        setQrCodeInfo({
          ...qrCodeInfo,
          has_qr_code: false,
          qr_code_url: null
        });
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to delete QR code');
      }
    } catch (err) {
      setError('Network error while deleting QR code');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button 
        onClick={handleOpen}
        className="bg-nord-frost2 hover:bg-nord-frost3 text-white px-3 py-1 rounded transition-colors"
      >
        QR Code
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="qr-code-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: bgColor,
          color: textColor,
          border: `2px solid ${borderColor}`,
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflow: 'auto',
          borderRadius: '8px',
          transition: 'all 0.2s ease-in-out'
        }}>
          <Typography id="qr-code-modal-title" variant="h6" component="h2" sx={{ color: textColor }}>
            QR Code for {shortUrl}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : qrCodeInfo?.has_qr_code ? (
            <Box sx={{ 
              mt: 3, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Box sx={{ 
                backgroundColor: 'white', 
                padding: 3, 
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
              }}>
                <img 
                  src={qrCodeInfo.qr_code_url}
                  alt="QR Code"
                  style={{ 
                    width: '200px', 
                    height: '200px',
                    display: 'block'
                  }}
                />
              </Box>

              <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                <Button 
                  variant="contained" 
                  href={qrCodeInfo.qr_code_url}
                  download
                  sx={{ 
                    bgcolor: '#5e81ac', // nord-frost4
                    '&:hover': { bgcolor: '#81a1c1' } // nord-frost3
                  }}
                >
                  Download
                </Button>
                <Button 
                  variant="outlined" 
                  onClick={deleteQrCode}
                  sx={{ 
                    color: '#bf616a', // nord-aurora1
                    borderColor: '#bf616a',
                    '&:hover': { 
                      bgcolor: 'rgba(191, 97, 106, 0.1)',
                      borderColor: '#bf616a' 
                    } 
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ mt: 3 }}>
              <Typography variant="body1" gutterBottom sx={{ color: textColor }}>
                Generate a QR code for this shortened URL:
              </Typography>

              <Button 
                variant="contained"
                onClick={generateQrCode}
                fullWidth
                sx={{ 
                  mt: 2,
                  bgcolor: '#5e81ac', // nord-frost4
                  '&:hover': { bgcolor: '#81a1c1' } // nord-frost3
                }}
              >
                Generate QR Code
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default QrCodeGenerator; 